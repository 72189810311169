import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavComponent } from './Modules/nav/nav.component';
import { HomeComponent } from './Modules/home/home.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Search } from './Shared/Search/search.component';
import { AuthenticationComponent } from './Modules/authentication/authentication.component';
import { RegistrationComponent } from './Modules/authentication/Registration/registration.component';
import { DashboardComponent } from './Modules/dashboard/dashboard.component';
import { ReactiveFormsModule } from '@angular/forms';
import { WorkareaComponent } from './Modules/workarea/workarea.component';
import { FormsModule } from '@angular/forms';
import { InsertionDirective } from './Shared/Popup/insertion.directive';
import { PopupComponent } from './Shared/Popup/popup.component';
import { DatePipe } from '@angular/common';
import { WorkareadetailsComponent } from './Modules/workareadetails/workareadetails.component';
import { RegistrationPageComponent } from './Modules/authentication/ResetPassword/registrationpage.component';
import { ForgotPasswordComponent } from './Modules/authentication/ForgotPassword/forgot-password.component';
import { ResetPasswordComponent } from './Modules/authentication/ResetPassword/ResetPassword.component';
import { IncominglogComponent } from './Modules/incominglog/incominglog.component';
import { OutgoinglogComponent } from './Modules/outgoinglog/outgoinglog.component';
import { XmlValidationComponent } from './Modules/xmlValidation/xml-validation.component';
import { AuthGuard } from './Core/Services/AuthGurd/auth.guard';
import { MyInterceptor } from './Core/Services/customHttpInterceptor';
import { CookieService } from 'ngx-cookie-service';
import { RequestCache } from './Core/Services/RequestCache';
import { LoaderComponent } from './Shared/loader/loader.component';
import { AlertComponent } from './Shared/alert/alert.component';
import { ViewAllCompletedTsComponent } from './Modules/workarea/completed/view-all-completed-ts.component';
import { GoogleAnalyticsService } from './Core/Services/GA/google-analytics.service';
import { FromExcelComponent } from './Modules/CreateJobs/FromExcel/FromExcel.component';
import { CreateJobsComponent } from './Modules/CreateJobs/CreateJobs.component';
import { FromFormComponent } from './Modules/CreateJobs/FromForm/FromForm.component';
import { PdfcontainerComponent } from './Shared/pdfcontainer/pdfcontainer.component';
import { DefendantDetailsComponent } from './Modules/CreateJobs/FromForm/DefendantDetailsForm/DefendantDetails.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonDocumentUploadComponent } from './Modules/CreateJobs/FromForm/CommonDocumentUpload/CommonDocumentUpload.Component';
import { ServicesComponent } from "./Modules/WindowsServices/ServicesComponent";
import { FormDBoxExcelComponent } from './Modules/CreateJobs/form-dbox-excel/form-dbox-excel.component';
import { AdminModule } from './Modules/admin/admin.module';
import { NhadminModule } from './Modules/nhadmin/nhadmin.module';
import { AdminnavComponent } from './Modules/admin/adminnav/adminnav.component';
import { NotfoundComponent } from './Modules/notfound/notfound.component'
import { RoleGuardService } from './Core/Services/AuthGurd/role-guard.service';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { DropboxDetailsComponent } from './Modules/dropbox-details/dropbox-details.component'
import { AgGridModule } from "ag-grid-angular";
import { CurrencyComponent } from './Modules/nhadmin/jobsdashboard/currency.component';
import { DefendantaddressComponent } from './Modules/nhadmin/reports/defendantaddress/defendantaddress.component';
import { ClientsModule } from './Modules/clients/clients.module';
import { NgxCaptchaModule } from 'ngx-captcha';

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    HomeComponent,
    Search,
    AuthenticationComponent,
    DashboardComponent,
    WorkareaComponent,
    RegistrationPageComponent,
    ForgotPasswordComponent,
    PopupComponent,
    ResetPasswordComponent,
    RegistrationComponent,
    IncominglogComponent,
    OutgoinglogComponent,
    XmlValidationComponent,
    WorkareadetailsComponent,
    InsertionDirective,
    LoaderComponent,
    AlertComponent,
    ViewAllCompletedTsComponent,
    FromExcelComponent,
    CreateJobsComponent,
    FromFormComponent,
    PdfcontainerComponent,
    DefendantDetailsComponent,
    CommonDocumentUploadComponent,
    ServicesComponent,
    FormDBoxExcelComponent,
    AdminnavComponent,
    NotfoundComponent,
    DropboxDetailsComponent,
    CurrencyComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    DragDropModule,
    AdminModule,
    NhadminModule,
    ClientsModule,
    NgMultiSelectDropDownModule.forRoot(),
    AgGridModule.withComponents([DropboxDetailsComponent, CurrencyComponent, DefendantaddressComponent]),
    NgxCaptchaModule
  ],
  providers: [DatePipe, AuthGuard, GoogleAnalyticsService, { provide: HTTP_INTERCEPTORS, useClass: MyInterceptor, multi: true }, CookieService, RequestCache, RoleGuardService],
  bootstrap: [AppComponent],
  entryComponents: [PopupComponent, DropboxDetailsComponent]
})

export class AppModule {

  constructor(protected _googleAnalyticsService: GoogleAnalyticsService) { }

  ngDoBootstrap() {
    // we can setup lot of stuff here before app gets booted.
  }

}
